<template>
  <div
    class="
      _100vh
      footer
      white--text
      d-flex
      flex-column
      justify-space-between
      px-2
    "
  >
    <v-row>
      <v-col cols="12" md="3"
        ><img src="../../assets/img/logopam.png" height="100px" alt=""
      /></v-col>
      <v-col cols="12" md="2"
        ><h3>Our Service</h3>
        <v-divider class="white"></v-divider>
        <router-link to="/public/konseling" class="__reset_decor">
          <p class="white--text">Konseling</p>
        </router-link>
      </v-col>
      <v-col cols="12" md="2"
        ><h3>About Us</h3>
        <v-divider class="white"></v-divider>
        <router-link to="/about" class="__reset_decor">
          <p class="white--text">About us</p>
          <p class="white--text" @click="scrollTo('visi')">visi</p>
        </router-link>
      </v-col>
      <v-col cols="12" md="2"
        ><h3>Other</h3>
        <v-divider class="white"></v-divider>
        <router-link to="/public/article" class="menu text-decoration"
          ><p class="white--text">Article</p>
        </router-link>
        <router-link to="/public/ebook" class="menu text-decoration"
          ><p class="white--text">E-book</p>
        </router-link>
        <router-link to="/noAuth/faq" class="menu text-decoration"
          ><p class="white--text">FAQ</p>
        </router-link>
        <router-link to="/noAuth/privacy" class="menu text-decoration"
          ><p class="white--text">Privacy</p>
        </router-link>
      </v-col>
      <v-col cols="12" md="2"
        ><h3>Contact us</h3>
        <v-divider class="white"></v-divider>
        <p>alumnimapropsiugm@gmail.com</p>
      </v-col>
      <v-col cols="12" md="2"></v-col>
    </v-row>
    <footer class="pa-5">
      <h4 class="text-center">Copyright @ 2021 All Right reserved</h4>
    </footer>
  </div>
</template>

<script>
export default {
  name: "footers",
  methods: {
    down() {
      let menu = document.querySelector("._nav-menu");
      menu.style.top = "0";
    },
    SwipeUp() {
      let menu = document.querySelector("._nav-menu");
      menu.style.top = "-400px";
    },
    scrollTo(menu) {
      this.$router.push(`/about/#${menu}`);
    }
  }
};
</script>

<style scoped>
.footer {
  position: relative;
  background: url("../../assets/img/memphis.png");
  background-position: center;
  background-size: cover;
  padding-top: 100px;
}
h3 {
  margin-bottom: 5px;
}
.__reset_decor {
  text-decoration: none;
}
</style>
