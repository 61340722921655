<template>
  <div class="_full-w">
    <Nav @scrollTo="scrollTo" />
    <HeroCover
      v-bind:title="'About Us'"
      v-bind:cover="require('../../assets/img/hero_cover.png')"
    />
    <div class="_100vh mt-5" v-if="content">
      <div class="d-flex justify-center pa-3" id="about">
        <div class="__square__radius">
          <div class="__title d-flex align-center pa-3">
            <img
              src="../../assets/img/fav.png"
              alt="logo"
              class="mr-3"
              height="70px"
            />
            <h1 class="purple--text">Tentang Pampsi</h1>
          </div>
          <div class="px-5" v-html="content.about_us"></div>
        </div>
      </div>
      <div class="temp__vi">
        <div class="__half pa-3" id="visi">
          <div class="__square__radius">
            <div class="__title d-flex align-center pa-3">
              <img
                src="../../assets/img/fav.png"
                alt="logo"
                class="mr-3"
                height="70px"
              />
              <h1 class="purple--text">Visi</h1>
            </div>
            <div class="pa-5">
              <p class="text-center">
                <i>{{ content.visi }}</i>
              </p>
            </div>
          </div>
        </div>
        <div class="__half pa-3">
          <div class="__square__radius">
            <div class="__title d-flex align-center pa-3">
              <img
                src="../../assets/img/fav.png"
                alt="logo"
                class="mr-3"
                height="70px"
              />
              <h1 class="purple--text">Misi</h1>
            </div>
            <div class="pa-3">
              <section
                class="d-flex mb-1"
                v-for="(item, idx) in content.misi"
                :key="idx"
              >
                <div class="mr-3">
                  <v-icon>mdi-spa</v-icon>
                </div>
                <p class="ma-0">
                  {{ item }}
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="milestone pa-3">
      <div class="__square__radius" v-if="milestone">
        <h1 class="purple--text text-center">Milestone</h1>
        <div class="my-3 pa-5">
          <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item
              v-for="(item, i) in milestone"
              :key="i"
              dense
              color="purple"
            >
              <v-card class="d-flex">
                <div style="width: 85%" class="pa-3">
                  <div class="text-h6">{{ item.judul }}</div>
                  <div class="white text--primary">
                    <p>
                      {{ item.keterangan }}
                    </p>
                  </div>
                </div>
                <div class="d-flex" style="width: 15%">
                  <v-divider vertical></v-divider>
                  <div
                    class="text-center d-flex align-center justify-center"
                    style="width: 99%"
                  >
                    <div>
                      <h3 class="l_height">
                        {{ $date(item.waktu).format("MMM") }}
                      </h3>
                      <h1 class="l_height">
                        {{ $date(item.waktu).format("DD") }}
                      </h1>
                      <h3 class="l_height">
                        {{ $date(item.waktu).format("YYYY") }}
                      </h3>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Nav from "../../components/General/Nav.vue";
import HeroCover from "../../components/_base/heroCover.vue";
import Footer from "./Footer.vue";

export default {
  name: "AboutUs",
  components: {
    HeroCover,
    Footer,
    Nav
  },
  computed: {
    ...mapState({
      content: state => state.aboutus.about,
      milestone: state => state.aboutus.milestone
    })
  },
  data() {
    return {
      loading: false,
      items: [
        {
          color: "red lighten-2",
          icon: "mdi-star"
        },
        {
          color: "purple darken-1",
          icon: "mdi-book-variant"
        },
        {
          color: "green lighten-1",
          icon: "mdi-airballoon"
        },
        {
          color: "indigo",
          icon: "mdi-buffer"
        }
      ]
    };
  },
  mounted() {
    this.fetch();
    this.fetchMilestone();
    this.$nextTick(() => {
      if (this.$route.hash) {
        this.scrollTo(this.$route.hash);
      }
    });
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store.dispatch("aboutus/getAbout").then(data => {
        if (data) {
          this.loading = false;
        }
      });
    },
    fetchMilestone() {
      this.loading = true;
      this.$store.dispatch("aboutus/getMilestone").then(data => {
        if (data) {
          this.loading = false;
        }
      });
    },
    scrollTo(menu) {
      this.$router.push(`/#${menu}`);
    }
  }
};
</script>

<style>
/* *{ text-transform: none !important; } */
.__square__radius {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 13px rgba(128, 0, 128, 0.229) solid;
  /* box-shadow: 0 0 0 13px rgba(128, 0, 128, 0.229); */
}
.__half {
  width: 50%;
}
.temp__vi {
  display: flex;
}
.l_height {
  line-height: normal;
}

@media (max-width: 576px) {
  .__half {
    width: 100%;
  }
  .temp__vi {
    display: block;
  }
}
@media (max-width: 768px) {
  .temp__vi {
    display: block;
  }
  .__half {
    width: 100%;
  }
}
</style>
